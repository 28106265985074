<template>
  <div>
    <map-box
      class="mt-4 mb-4"
      title="ตำแหน่งที่ตั้งโรงไฟฟ้า"
      :fetchPromise="powerPlants"
      :markerImage="powerImage"
    />
    <map-box
      class="mt-4 mb-4"
      title="ตำแหน่งที่ตั้งโรงกลั่นน้ำมัน"
      :fetchPromise="oilRefineries"
      :markerImage="oilImage"
    />
    <map-box
      class="mt-4 mb-4"
      title="ตำแหน่งที่ตั้งโรงแยกก๊าซธรรมชาติ"
      :fetchPromise="gasSeperationPlants"
      :markerImage="factoryImage"
    />
  </div>
</template>

<script>
import power from '@/assets/powerplant.svg';
import oil from '@/assets/oilrefinery.svg';
import factory from '@/assets/gasseperationplant.svg';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import MapBox from '@/components/MapBox.vue';

export default {
  name: 'infrastructure',
  components: {
    MapBox,
  },
  data() {
    return {
      topicNumber: '2.5',
    };
  },
  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
    powerImage() {
      return power;
    },
    oilImage() {
      return oil;
    },
    factoryImage() {
      return factory;
    },
  },

  mounted() {},

  methods: {
    ...mapActions('mondas', [
      'fetchPowerPlant',
      'fetchOilRefinery',
      'fetchGasSeperationPlant',
    ]),
    chartIdentifier(number) {
      return this.topicNumber + '.' + number;
    },
    autoNumber(value) {
      return this.$options.filters.auto_number(value);
    },
    powerPlants() {
      return this.fetchPowerPlant(this.currentProvince).then((data) => {
        const result = data.map((item) => {
          const location = item.data;
          return {
            lat: location.lat,
            long: location.long,
            title: `${location.name}<br>ประเภท: ${
              location.type
            }<br>เทคโนโลยีการผลิต: ${location.technology}<br>เชื้อเพลิง: ${
              location.fuel
            }<br>กำลังผลิตตามสัญญา(MW): ${this.autoNumber(location.value)}`,
          };
        });
        const tables = [
          [
            'ชื่อ',
            'ประเภท',
            'เทคโนโลยีการผลิต',
            'เชื้อเพลิง',
            'กำลังผลิตตามสัญญา (MW)',
          ],
        ];
        data.forEach((item) => {
          const location = item.data;
          tables.push([
            location.name,
            location.type,
            location.technology,
            location.fuel,
            location.value,
          ]);
        });
        return {
          locations: result,
          dataTables: tables,
        };
      });
    },
    oilRefineries() {
      return this.fetchOilRefinery(this.currentProvince).then((data) => {
        const result = data.map((item) => {
          const location = item.data;
          return {
            lat: location.lat,
            long: location.long,
            title: `${location.name}<br>กำลังผลิต (bbl/day): ${this.autoNumber(
              location.value
            )}`,
          };
        });
        const tables = [['ชื่อ', 'กำลังผลิต (bbl/day)']];
        data.forEach((item) => {
          const location = item.data;
          tables.push([location.name, location.value]);
        });
        return {
          locations: result,
          dataTables: tables,
        };
      });
    },
    gasSeperationPlants() {
      return this.fetchGasSeperationPlant(this.currentProvince).then((data) => {
        const result = data.map((item) => {
          const location = item.data;
          return {
            lat: location.lat,
            long: location.long,
            title: `${
              location.name
            }<br>กำลังผลิต (Millions scf/day): ${this.autoNumber(
              location.value
            )}`,
          };
        });
        const tables = [['ชื่อ', 'กำลังผลิต (Millions scf/day)']];
        data.forEach((item) => {
          const location = item.data;
          tables.push([location.name, location.value]);
        });
        return {
          locations: result,
          dataTables: tables,
        };
      });
    },
  },
};
</script>

<style scoped></style>
